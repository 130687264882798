import { BellOutlined, MenuOutlined } from "@ant-design/icons"
import { Badge, } from "antd"
import * as React from "react"
import getNumProcessesActionRequired from "../../commonConfiguration/processes/getNumProcessesActionRequired"
import { injectIntl, type IntlShape } from "react-intl"

const BADGETIMEOUT = 5000

export interface Props {
  isLoggedIn: boolean
  selectNotifications: () => void
  toggleSideMenu: () => void
  logout: (path?: string) => void
  vacationRequestsAvailable: boolean
  processType: string
  departmentId: string
  notificationsChanged: (n: number) => void
  intl: IntlShape
}

interface State {
  notificationCount: number
  calendarURL?: string
}

export class MobileHeader extends React.Component<Props, State> {
  private reload: () => void
  private notificationTimeout?: number

  constructor(props: Props) {
    super(props)
    this.state = {
      notificationCount: 0,
    }
    this.toggleSideMenu = this.toggleSideMenu.bind(this)
    this.selectNotifications = this.selectNotifications.bind(this)
    this.updateNotificationBadge = this.updateNotificationBadge.bind(this)
    this.reload = () => (window.location.href = "/mobile")
  }

  componentDidMount(): void {
    void this.updateNotificationBadge()
  }

  componentDidUpdate(): void {
    void this.updateNotificationBadge()
  }

  componentWillUnmount(): void {
    if (this.notificationTimeout !== undefined) {
      window.clearTimeout(this.notificationTimeout)
    }
  }

  render(): JSX.Element {
    let cls = "mobile-notification-button"
    if (!this.props.vacationRequestsAvailable) {
      cls = `${cls} mobile-disabled`
    }
    return (
      <div className="mobile-header">
        <img
          onClick={this.reload}
          alt="mina.works"
          src="mina-works-logo-white.svg"
          className="mobile-logo"
          style={{ cursor: "pointer" }}
        ></img>
        {this.props.isLoggedIn ? (
          <>
            <Badge
              showZero={false}
              offset={[-5, 6]}
              count={this.state.notificationCount}
              className={cls}
              style={{
                backgroundColor: "#B3445D",
              }}
            >
              <BellOutlined onClick={this.selectNotifications}></BellOutlined>
            </Badge>
            <MenuOutlined
              onClick={this.toggleSideMenu}
              className="mobile-menu-button"
            ></MenuOutlined>
          </>
        ) : null}
      </div>
    )
  }

  private async updateNotificationBadge() {
    if (this.notificationTimeout) {
      window.clearTimeout(this.notificationTimeout)
      this.notificationTimeout = undefined
    }
    try {
      const notificationCount = await getNumProcessesActionRequired(
        this.props.processType,
        this.props.departmentId
      )
      if (notificationCount !== this.state.notificationCount) {
        this.setState({ notificationCount }, () =>
          this.props.notificationsChanged(notificationCount)
        )
      }
    } finally {
      if (!this.notificationTimeout) {
        this.notificationTimeout = window.setTimeout(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          this.updateNotificationBadge,
          BADGETIMEOUT
        )
      }
    }
  }

  private selectNotifications() {
    this.props.selectNotifications()
  }

  private toggleSideMenu() {
    this.props.toggleSideMenu()
  }
}

const MobileHeaderWithIntl = injectIntl(MobileHeader)
export default MobileHeaderWithIntl
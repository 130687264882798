const messages = {
  "PBBForm.name": "Name",
  "PBBForm.abbrv": "Abbrv.",
  "PBBForm.allDay": "Allow all day",
  "PBBForm.newButton": "Add plan building block",
  "PBBForm.formModalHeader": "Edit plan building block",
  "PBBForm.addSegment": "Add new segment",
  "PBBForm.segment.addBreak": "Add new break",
  "PBBForm.segment.startTime": "Starttime",
  "PBBForm.segment.endTime": "Endtime",
  "PBBForm.segment.calendarentrytype": "Calenderentry",
  "PBBForm.segment.breaks": "Breaks",
  "PBBForm.segment.unavailabilities": "Unavailabilities",
  "PBBForm.segment.show": "Show",
  "PBBForm.segment.hide": "Hide",
  "PBBForm.endNextDay": "Overnight",
  "PBBForm.break.approxStartTime": "Approx. Starttime",
  "PBBForm.break.approxEndTime": "Approx. Endtime",
  "PBBForm.break.duration": "Duration in minutes",
  "PBBForm.error.header": "Error in form",
  "PBBForm.error.content": "Please add all required fields.",
  "PBBForm.error.missingName": "Please enter a name",
  "PBBForm.error.missingAbbrv": "Please enter a abbreviation",
  "PBBForm.error.missingCetId": "Please select the calendarentrytype",
  "PBBForm.error.missingSegmentStartTime": "Please set the start time",
  "PBBForm.error.missingSegmentEndTime": "Please set the end time",
  "PBBForm.error.missingBreakStartTime": "Please set the start time",
  "PBBForm.error.missingBreakEndTime": "Please set the end time",
  "PBBForm.error.breakDurationLength":
    "The break duration is longer than the planned break interval",
  "PBBForm.error.breakStartOutsideSegment":
    "The planned break interval start before the segment",
  "PBBForm.error.breakEndOutsideSegment":
    "The planned break interval ends after the segment",
  "PBBForm.error.overlappingStartTime": "Starttime overlap",
  "PBBForm.error.overlappingEndTime": "Endtime overlap",
  "PBBForm.reallyDelete": "Do you really want to remove this entry?",
  "PBBForm.segment.orgunit": "Organisational unit",
  "PBBForm.segment.orgunit.placeholder": "Home department",
}

export default messages

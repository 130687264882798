/**
 * This is a temporary repository for all collected localizations
 */

// TODO: Make loadintl find and match all locales

import loginDe from "../modules/login/locales/messages-de.js"
import loginEn from "../modules/login/locales/messages-en.js"
import orgChartDe from "../modules/orgChart/locales/messages-de.js"
import orgChartEn from "../modules/orgChart/locales/messages-en.js"
import clientDe from "../modules/clientManager/locales/messages-de.js"
import clientEn from "../modules/clientManager/locales/messages-en.js"
import userDe from "../modules/userManager/locales/messages-de.js"
import userEn from "../modules/userManager/locales/messages-en.js"
import languageDe from "../modules/languagePicker/locales/messages-de.js"
import languageEn from "../modules/languagePicker/locales/messages-en.js"
import confirmDe from "../modules/confirmPassword/locales/messages-de.js"
import confirmEn from "../modules/confirmPassword/locales/messages-en.js"
import authorisationDe from "../modules/authorisation/locales/messages-de.js"
import authorisationEn from "../modules/authorisation/locales/messages-en.js"
import contextDe from "../shared/OUContext/locales/messages-de.js"
import contextEn from "../shared/OUContext/locales/messages-en.js"
import groupedUserSelectDe from "../shared/ui/GroupedUserSelect/locales/messages-de.js"
import groupedUserSelectEn from "../shared/ui/GroupedUserSelect/locales/messages-en.js"
import settingsDe from "../modules/settings/locales/messages-de.js"
import settingsEn from "../modules/settings/locales/messages-en.js"
import qualiEn from "../modules/qualifications/locales/messages-en.js"
import qualiDe from "../modules/qualifications/locales/messages-de.js"
import pbbTemplateEn from "../modules/pbbtemplateManager/locales/messages-en.js"
import pbbTemplateDe from "../modules/pbbtemplateManager/locales/messages-de.js"
import pbbEn from "../shared/ui/PBBForm/locales/messages-en.js"
import pbbDe from "../shared/ui/PBBForm/locales/messages-de.js"
import calendarentrytypesDe from "../modules/calendarentrytypes/locales/messages-de.js"
import calendarentrytypesEn from "../modules/calendarentrytypes/locales/messages-en.js"
import globalMessageDe from "../modules/globalMessage/locales/messages-de.js"
import globalMessageEn from "../modules/globalMessage/locales/messages-en.js"
import absenceOverviewDe from "../modules/reports/absenceOverview/locales/messages-de.js"
import absenceOverviewEn from "../modules/reports/absenceOverview/locales/messages-en.js"
import timeSheetDe from "../modules/reports/timeSheet/locales/messages-de.js"
import timeSheetEn from "../modules/reports/timeSheet/locales/messages-en.js"
import timeSheetBonusesDe from "../modules/reports/timeSheetBonuses/locales/messages-de.js"
import timeSheetBonusesEn from "../modules/reports/timeSheetBonuses/locales/messages-en.js"
import weeklyScheduleDe from "../modules/reports/weeklySchedule/locales/messages-de.js"
import weeklyScheduleEn from "../modules/reports/weeklySchedule/locales/messages-en.js"
import vacationListDe from "../modules/reports/vacationList/locales/messages-de.js"
import vacationListEn from "../modules/reports/vacationList/locales/messages-en.js"
import vacationSheetDe from "../modules/reports/vacationSheet/locales/messages-de.js"
import vacationSheetEn from "../modules/reports/vacationSheet/locales/messages-en.js"
import yearSummaryDe from "../modules/reports/yearSummary/locales/messages-de.js"
import yearSummaryEn from "../modules/reports/yearSummary/locales/messages-en.js"
import calculationDetailsDe from "../modules/reports/calculationDetails/locales/messages-de.js"
import calculationDetailsEn from "../modules/reports/calculationDetails/locales/messages-en.js"
import hourlyAnalysisDe from "../modules/reports/hourlyAnalysis/locales/messages-de.js"
import hourlyAnalysisEn from "../modules/reports/hourlyAnalysis/locales/messages-en.js"
import licenseStatsDe from "../modules/infoPages/licenseStats/locales/messages-de"
import licenseStatsEn from "../modules/infoPages/licenseStats/locales/messages-en"
import menuDe from "../modules/mainMenu/locales/messages-de"
import menuEn from "../modules/mainMenu/locales/messages-en"

const messages = {
  de: {
    "no-service-worker":
      "Es gibt Probleme mit den Browsereinstellungen. Dieser Browser unterstützt keine Service Worker. Möglicherweise müssen die Privatsphären-Einstellungen angepasst werden, um mina.works verwenden zu können! Unter Firefox ist es beispielsweise notwendig, die Einstellung 'Keine Chronik anlegen' auszuschalten. Falls Sie das Problem nicht lösen können wenden Sie sich bitte an den Support.",
    Week: "Woche",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    weekday: "Wochentag",
    weekdays: "Wochentage",
    everyday: "Jeder Tag",
    holiday: "Feiertag",
    holidays: "Feiertage",
    // redunant translations -> backend already translated them
    // but to avoid errors in console
    Montag: "Montag",
    Dienstag: "Dienstag",
    Mittwoch: "Mittwoch",
    Donnerstag: "Donnerstag",
    Freitag: "Freitag",
    Samstag: "Samstag",
    Sonntag: "Sonntag",
    Wochentag: "Wochentag",
    Feiertag: "Feiertag",
    Wochentage: "Wochentage",
    Feiertage: "Feiertage",
    selectMonth: "Monat auswählen",
    year: "Jahr",
    partial: "anteilig",
    mr: "Herr",
    mrs: "Frau",
    "prefix.dr": "Dr.",
    "prefix.ma": "M.A.",
    "prefix.masters": "Magister",
    "prefix.msc": "M.Sc.",
    "prefix.pharm": "Dipl. Pharm.",
    from: "von",
    until: "bis",
    Details: "Details",
    View: "Bearbeiten abbrechen",
    save: "Speichern",
    delete: "Löschen",
    invalidate: "Invalidieren",
    invalid: "ungültig",
    restore: "Wiederherstellen",
    cancel: "Abbrechen",
    "Cancel despite modifications?":
      "Es gibt Änderungen im Formular. Wollen Sie trotzdem abbrechen?",
    submit: "Übernehmen",
    required: "Erforderlich",
    Edit: "Bearbeiten",
    edit: "Bearbeiten",
    admin: "Administrator",
    "dev-version": "Version 0.9beta",
    imprint: "Impressum",
    "getstarted.tagline": "Unser Einsatz für Ihre Planung.",
    "getstarted.tagline2": "Einfach. Schnell. Unabhängig. Individuell.",
    "getstarted.main": "Los geht's",
    "getstarted.login": "Anmelden",
    "getstarted.welcome": "Willkommen",
    "menu.home": "Startseite",
    "menu.clients": "Mandanten",
    "menu.client": "Mandantenkonfiguration",
    "menu.users": "Mitarbeiter",
    "menu.userdata": "Eigene Daten",
    "menu.planner": "Planung",
    "menu.authorisation": "Berechtigungen",
    "menu.dayplanner": "Tagesplaner",
    "menu.weekplanner": "Wochenplaner",
    "menu.monthplanner": "Monatsplaner",
    "menu.vacationplanner": "Urlaubsplaner",
    "menu.qualifications": "Qualifikationen",
    "menu.userschedules": "Autopläne",
    "menu.planbuildingblocks": "Planbausteine",
    "menu.calendarentrytypes": "Kalendereintragstypen",
    "menu.vacationcalculation": "Urlaubsberechnung",
    "menu.planning": "Planung",
    "menu.events": "Events",
    "menu.orgchart": "Organigramm",
    "menu.configuration": "Konfiguration",
    "menu.personalsettings": "Nutzereinstellungen",
    "menu.userportal": "Mitarbeiterportal",
    "menu.settings": "Konfiguration",
    "menu.holidayupload": "Feiertage-Upload",
    "menu.collectiveagreements": "Tarife",
    "home.aboutus": `
      mina.works ist die Softwarelösung zur Einsatzplanung, Zeiterfassung und
      Mitarbeiterkommunikation. Über 1.100 Unternehmen im deutschsprachigen Raum
      mit zusammen mehr als 39.000 Mitarbeitern verlassen sich auf die
      Planungswerkzeuge der MEP24 Software GmbH.
    `,
    "settings.override": "Geerbte Werte Überschreiben?",
    "min.": "Min.",
    "+": "+",
    "-": "-",
    yes: "Ja",
    no: "Nein",
    Search: "Suche",
    more: "mehr",
    OrganisationalUnitDeletionNonEmptyError: "Organisationseinheit nicht leer",
    "Active Users still exists in organisational unit.":
      "Es befinden sich noch aktive Benutzer in dieser Organisationseinheit.",
    calculationProfiles: "Tarife",
    "calculationProfile.defaultRules": "Standard Berechnungsregeln",
    roles: "Rollen",
    retroactiveLimits: "Vergangenheitseinstellungen",
    ...loginDe,
    ...orgChartDe,
    ...clientDe,
    ...userDe,
    ...languageDe,
    ...confirmDe,
    ...contextDe,
    ...groupedUserSelectDe,
    ...authorisationDe,
    ...settingsDe,
    ...qualiDe,
    ...calendarentrytypesDe,
    ...pbbTemplateDe,
    ...pbbDe,
    ...globalMessageDe,
    ...absenceOverviewDe,
    ...timeSheetDe,
    ...timeSheetBonusesDe,
    ...weeklyScheduleDe,
    ...vacationListDe,
    ...vacationSheetDe,
    ...yearSummaryDe,
    ...calculationDetailsDe,
    ...hourlyAnalysisDe,
    ...licenseStatsDe,
    ...menuDe,
  },
  en: {
    "no-service-worker":
      "This browser doesn't support service workers. Please adjust your privacy settings in order for mina.works to run correctly!",
    Week: "Week",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
    weekday: "Weekday",
    weekdays: "Weekdays",
    everyday: "Every day",
    holiday: "Holiday",
    holidays: "Holidays",
    Weekday: "Weekday",
    Weekdays: "Weekdays",
    Holiday: "Holiday",
    Holidays: "Holidays",
    selectMonth: "Select month",
    mr: "Mr.",
    mrs: "Mrs.",
    "prefix.dr": "PhD",
    "prefix.ma": "M.A.",
    "prefix.masters": "Master`s degree",
    "prefix.msc": "M.Sc.",
    "prefix.pharm": "Dipl. Pharm.",
    year: "Year",
    partial: "partial",
    from: "from",
    until: "until",
    save: "Save",
    Details: "Details",
    View: "Back",
    delete: "Delete",
    invalidate: "Invalidate",
    invalid: "invalid",
    restore: "Restore",
    cancel: "Cancel",
    "Cancel despite modifications?":
      "The form has been modified. Would you like to cancel anyway?",
    submit: "Submit",
    required: "Required",
    Edit: "Edit",
    edit: "Edit",
    admin: "Administrator",
    "dev-version": "Version 0.9beta",
    imprint: "Imprint",
    "getstarted.tagline": "Planning your resources is our business.",
    "getstarted.tagline2": "Simple. Fast. Independent. Customized.",
    "getstarted.main": "Get started",
    "getstarted.login": "Login",
    "getstarted.welcome": "Welcome",
    "menu.home": "Home",
    "menu.clients": "Clients",
    "menu.client": "Client Configuration",
    "menu.users": "Employees",
    "menu.userdata": "Own data",
    "menu.planner": "Calendar",
    "menu.events": "Events",
    "menu.authorisation": "Authorisation",
    "menu.qualifications": "Qualifications",
    "menu.userschedules": "Fixed Employee Schedules",
    "menu.planbuildingblocks": "Plan Segments",
    "menu.calendarentrytypes": "Calendar Entry Types",
    "menu.vacationcalculation": "Vacation calculation",
    "menu.dayplanner": "Day Planner",
    "menu.weekplanner": "Week Planner",
    "menu.monthplanner": "Month Planner",
    "menu.vacationplanner": "Vacation Planner",
    "menu.planning": "Planning",
    "menu.orgchart": "Organisational Chart",
    "menu.configuration": "Configuration",
    "menu.personalsettings": "User Settings",
    "menu.userportal": "Employee Portal",
    "menu.settings": "Settings",
    "menu.holidayupload": "Holidays-Upload",
    "menu.collectiveagreements": "Contract bases",
    "home.aboutus": `
      mina.works is the software solution for resource planning, time tracking,
      and company communication.
    `,
    "settings.override": "Override inherited values?",
    "min.": "min.",
    "+": "+",
    "-": "-",
    yes: "Yes",
    no: "No",
    Search: "Search",
    more: "more",
    OrganisationalUnitDeletionNonEmptyError:
      "Organisational Unit is not empty.",
    "Active Users still exists in organisational unit.":
      "Active users still exist in this Organisational Unit.",
    calculationProfiles: "Calculation Profiles",
    "calculationProfile.defaultRules": "Default Rules",
    roles: "Roles",
    retroactiveLimits: "Retroactive limits",
    ...loginEn,
    ...orgChartEn,
    ...clientEn,
    ...userEn,
    ...languageEn,
    ...confirmEn,
    ...contextEn,
    ...groupedUserSelectEn,
    ...authorisationEn,
    ...settingsEn,
    ...qualiEn,
    ...calendarentrytypesEn,
    ...pbbTemplateEn,
    ...pbbEn,
    ...globalMessageEn,
    ...absenceOverviewEn,
    ...timeSheetEn,
    ...timeSheetBonusesEn,
    ...weeklyScheduleEn,
    ...vacationListEn,
    ...vacationSheetEn,
    ...yearSummaryEn,
    ...calculationDetailsEn,
    ...hourlyAnalysisEn,
    ...licenseStatsEn,
    ...menuEn,
  },
}

export default messages

import * as React from "react"
import MobileHeader from "./scaffolding/MobileHeader"
import MobileLoginForm from "./scaffolding/MobileLoginForm"

import "./less/mobile.less"
import MobileSideMenu from "./scaffolding/MobileSideMenu"
import checkAvailableProcessType from "../commonConfiguration/processes/checkAvailableProcessType"
import { IntlProvider, useIntl } from "react-intl"
import messages from "./locales/messages"
import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import areFeaturesAvailable from "../shared/lib/featuresetUtils/areFeaturesAvailable"
import PrimaryLoggedInUserScreen from "./PrimaryLoggedInUserScreen"
import MobileBottomMenu from "./scaffolding/MobileBottomMenu"
import { Button, Spin } from "antd"
import { DASHBOARD, REQUESTS } from "./routing/routing"
import { VACATIONREQUEST } from "../commonConfiguration/processes/processTypes"

// let showInstallPrompt = false
//
// window.addEventListener("beforeinstallprompt", e => {
//   e.preventDefault()
//   showInstallPrompt = true
//   console.log("beforeinstallprompt triggered!")
// })

type LoginFun = (
  username: string,
  password: string,
  path: string
) => Promise<LoginResult>
export interface Props {
  isLoggedIn: boolean
  login: LoginFun
  resetPassword: (username: string, path: string) => void
  logout: () => void
  userId: string
  userClientId: string
  userDepartmentId: string
  locale: string
  username?: string
  saveUserSettings: (settings: { notifyViaEmail: boolean }) => Promise<void>
  loadUserSettings: () => Promise<{ notifyViaEmail: boolean }>
}

interface LoginResult {
  success: boolean
  username: string
  orgunitId: string
}

export default function MobileApp(props: Props): JSX.Element {
  const [displayModule, setDisplayModule] = React.useState<string>(DASHBOARD)
  const [sideMenuOpen, setSideMenuOpen] = React.useState<boolean>(false)
  const [finishedLoading, setFinishedLoading] = React.useState<boolean>(false)
  const [availableFeatures, setAvailableFeatures] = React.useState<
    FeatureType[]
  >([])
  const [notificationCount, setNotificationCount] = React.useState<number>(0)
  const [vacationRequestsAvailable, setVacationRequestsAvailable] =
    React.useState<boolean>(false)
  const [username, setUsername] = React.useState<string | undefined>()
  const [userDepartmentId, setUserDepartmentId] = React.useState<
    string | undefined
  >()
  const effectiveUserDepartmentId = React.useMemo(
    () => userDepartmentId ?? props.userDepartmentId,
    [userDepartmentId, props.userDepartmentId]
  )
  const effectiveUsername = React.useMemo(
    () => username ?? props.username,
    [username, props.username]
  )
  const isLoggedIn = React.useMemo(() => {
    return props.isLoggedIn && availableFeatures.includes("MobileApp")
  }, [props.isLoggedIn, availableFeatures])
  const prevLoggedInRef = React.useRef<boolean>(false)
  const prevUserIdRef = React.useRef<string | undefined>()
  const prevUserDepartmentIdFromPropsRef = React.useRef<string | undefined>()
  const prevUserDepartmentIdRef = React.useRef<string | undefined>()
  const load = React.useCallback(async () => {
    const prevLoggedIn = prevLoggedInRef.current
    prevLoggedInRef.current = props.isLoggedIn
    const prevUserId = prevUserIdRef.current
    prevUserIdRef.current = props.userId
    const prevUserDepartmentIdFromProps =
      prevUserDepartmentIdFromPropsRef.current
    prevUserDepartmentIdFromPropsRef.current = props.userDepartmentId
    const prevUserDepartmentId = prevUserDepartmentIdRef.current
    prevUserDepartmentIdRef.current = userDepartmentId
    if (
      prevLoggedIn !== props.isLoggedIn ||
      prevUserId !== props.userId ||
      prevUserDepartmentIdFromProps !== props.userDepartmentId ||
      prevUserDepartmentId !== userDepartmentId
    ) {
      const clientId: string | undefined = props.userClientId
      const f = await areFeaturesAvailable({
        clientId,
        featureTypes: ["MobileApp", "LoggedDays", "VacationRequests"],
      })
      const avail: FeatureType[] = []
      if (f.VacationRequests.available) {
        avail.push("VacationRequests")
      }
      if (f.MobileApp.available) {
        avail.push("MobileApp")
      }
      if (f.LoggedDays.available) {
        avail.push("LoggedDays")
      }
      setVacationRequestsAvailable(
        (await checkAvailableProcessType(
          VACATIONREQUEST,
          effectiveUserDepartmentId
        )) && avail.includes("VacationRequests")
      )
      setAvailableFeatures(avail)
    }
  }, [
    effectiveUserDepartmentId,
    props.isLoggedIn,
    props.userClientId,
    props.userDepartmentId,
    props.userId,
    userDepartmentId,
  ])
  React.useEffect(() => {
    setSideMenuOpen(false)
  }, [displayModule])
  const setUserData = React.useCallback((uname: string, deptId?: string) => {
    setUsername(uname)
    setUserDepartmentId(deptId)
  }, [])
  const toggleSideMenu = React.useCallback(() => {
    setSideMenuOpen(s => !s)
  }, [])
  const notificationsChanged = React.useCallback((n: number) => {
    setNotificationCount(n)
  }, [])
  const navigateToNotifications = React.useCallback(() => {
    setDisplayModule(REQUESTS)
  }, [])
  const changeDisplayedModule = React.useCallback((mod: string) => {
    setDisplayModule(mod)
  }, [])
  const previousSearchRef = React.useRef<string>()
  React.useEffect(() => {
    const previousSearch = previousSearchRef.current
    const search = window.location.search
    previousSearchRef.current = search
    const params = new URLSearchParams(search)
    const moduleName = params.get("display-module")
    if (
      moduleName?.startsWith(REQUESTS) &&
      displayModule !== moduleName &&
      previousSearch !== search
    ) {
      setDisplayModule(REQUESTS)
    } else {
      void load().then(() =>
        // Add timeout to make sure the state has propagated correctly
        window.setTimeout(() => setFinishedLoading(true), 400)
      )
    }
  }, [
    props.isLoggedIn,
    props.userId,
    effectiveUserDepartmentId,
    load,
    displayModule,
  ])
  const closeSideMenu = React.useCallback(() => setSideMenuOpen(false), [])
  return (
    <IntlProvider
      messages={messages[props.locale]}
      defaultLocale="de"
      locale={props.locale}
    >
      <div className="mobile-app">
        {/* showInstallPrompt ? (
            <div className="mobile-install-prompt">
              {messages[props.locale].installMobileIcon}
            </div>
          ) : null */}
        {isLoggedIn ? (
          <MobileHeader
            vacationRequestsAvailable={vacationRequestsAvailable}
            selectNotifications={navigateToNotifications}
            isLoggedIn={props.isLoggedIn}
            toggleSideMenu={toggleSideMenu}
            logout={props.logout}
            departmentId={effectiveUserDepartmentId}
            processType={VACATIONREQUEST}
            notificationsChanged={notificationsChanged}
          />
        ) : null}
        {isLoggedIn ? (
          <MobileSideMenu
            saveUserSettings={props.saveUserSettings}
            loadUserSettings={props.loadUserSettings}
            username={effectiveUsername ?? ""}
            vacationRequestsAvailable={vacationRequestsAvailable}
            setDisplayModule={setDisplayModule}
            displayModule={displayModule}
            open={sideMenuOpen}
            close={closeSideMenu}
            logout={props.logout}
          />
        ) : null}
        {isLoggedIn && props.userClientId ? (
          <PrimaryLoggedInUserScreen
            displayModule={displayModule}
            clientId={props.userClientId}
            departmentId={props.userDepartmentId}
            employeeId={props.userId}
            changeDisplayedModule={changeDisplayedModule}
            availableFeatures={availableFeatures}
            saveUserSettings={props.saveUserSettings}
            loadUserSettings={props.loadUserSettings}
            notificationCount={notificationCount}
          ></PrimaryLoggedInUserScreen>
        ) : (
          <LoggedOutOrAdmin
            finishedLoading={finishedLoading}
            isLoggedIn={isLoggedIn}
            logout={props.logout}
            login={props.login}
            resetPassword={props.resetPassword}
            setUserData={setUserData}
          ></LoggedOutOrAdmin>
        )}
        {isLoggedIn ? (
          <MobileBottomMenu
            username={effectiveUsername ?? ""}
            vacationRequestsAvailable={vacationRequestsAvailable}
            setDisplayModule={setDisplayModule}
            displayModule={displayModule}
          />
        ) : null}
      </div>
    </IntlProvider>
  )
}

function LoggedOutOrAdmin({
  finishedLoading,
  isLoggedIn,
  logout,
  login,
  resetPassword,
  setUserData,
}: {
  finishedLoading: boolean
  isLoggedIn: boolean
  logout: () => void
  login: LoginFun
  resetPassword: (u: string, p: string) => void
  setUserData: (u: string, did?: string) => void
}): JSX.Element {
  const intl = useIntl()
  if (!finishedLoading) {
    return <Spin></Spin>
  } else if (isLoggedIn) {
    const m = messages[intl.locale.substring(0, 2)] ?? messages.de
    return (
      <div className="mobile-admin-message">
        <p style={{ fontWeight: "bold" }}>{m.adminmsg1}</p>
        <p>{m.adminmsg2}</p>
        <p>{m.adminmsg3}</p>
        <p>
          <Button type="primary" onClick={logout}>
            {m.Logout}
          </Button>
        </p>
      </div>
    )
  } else {
    return (
      <MobileLoginForm
        login={login}
        setUserData={setUserData}
        resetPassword={resetPassword}
      />
    )
  }
}

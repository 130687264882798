const messages = {
  "PBBForm.name": "Bezeichnung",
  "PBBForm.abbrv": "Kürzel",
  "PBBForm.allDay": "Urlaub/Krankheit",
  "PBBForm.newButton": "Planbaustein hinzufügen",
  "PBBForm.formModalHeader": "Planbaustein bearbeiten",
  "PBBForm.addSegment": "Neuen Zeitabschnitt anlegen",
  "PBBForm.segment.addBreak": "Neue Pause anlegen",
  "PBBForm.segment.startTime": "Von",
  "PBBForm.segment.endTime": "Bis",
  "PBBForm.segment.calendarentrytype": "Tätigkeit",
  "PBBForm.segment.calendarentrytype.all-day": "Urlaub/Krankheit",
  "PBBForm.segment.breaks": "Pausen",
  "PBBForm.segment.unavailabilities": "Urlaub/Krankheit",
  "PBBForm.segment.show": "Anzeigen",
  "PBBForm.segment.hide": "Verstecken",
  "PBBForm.endNextDay": "Über Nacht",
  "PBBForm.break.startTime": "Von",
  "PBBForm.break.endTime": "Bis",
  "PBBForm.break.duration": "Dauer in Minuten",
  "PBBForm.error.header": "Formularfehler",
  "PBBForm.error.content":
    "Bitte alle Pflichtfelder hinzufügen und auf Datumslogik achten.",
  "PBBForm.error.missingName": "Bitte ein Bezeichnung angeben",
  "PBBForm.error.missingAbbrv": "Bitte ein Kürzel angeben",
  "PBBForm.error.missingCetId": "Bitte Tätigkeit/Urlaub/Krankheit auswählen",
  "PBBForm.error.missingSegmentStartTime": "Bitte einen Startzeitpunkt angeben",
  "PBBForm.error.missingSegmentEndTime": "Bitte einen Endzeitpunkt angeben",
  "PBBForm.error.missingBreakStartTime": "Bitte einen Startzeitpunkt angeben",
  "PBBForm.error.missingBreakEndTime": "Bitte einen Endzeitpunkt angeben",
  "PBBForm.error.breakDurationLength":
    "Die Dauer übersteigt das geplante Zeitintervall",
  "PBBForm.error.breakStartOutsideSegment":
    "Die Pause beginnt vor dem Zeitabschnitt",
  "PBBForm.error.breakEndOutsideSegment":
    "Die Pause endet nach dem Zeitabschnitt",
  "PBBForm.error.overlappingStartTime": "Startzeitpunkt überlappt",
  "PBBForm.error.overlappingEndTime": "Endzeitpunkt überlappt",
  "PBBForm.reallyDelete": "Wollen Sie den Eintrag wirklich entfernen?",
  "PBBForm.segment.orgunit": "Abteilung",
  "PBBForm.segment.orgunit.placeholder": "Stammabbteilung",
}

export default messages

import * as React from "react"
import { post } from "../../shared/lib/requestUtils"
import { CalendarOutlined, CopyOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"
import { Button, Input, Space, type InputRef } from "antd"

const SUBSCRIBEURL = "/rest/planner/subscribe"

export default function MobileSubscribeLink(): JSX.Element {
  const intl = useIntl()
  const [icsUrl, setIcsUrl] = React.useState<string | undefined>()
  const createSubscription = React.useCallback(() => {
    const go = async () => {
      const r = await post<{
        success: boolean
        key?: string
        calendarId?: string
      }>(SUBSCRIBEURL, {} as Record<never, never>)
      if (r.success && r.key && r.calendarId) {
        const url = `${window.document.location.protocol}//${encodeURI(
          window.document.location.host
        )}/rest/planner/minacalendar?calendar_id=${encodeURIComponent(
          r.calendarId
        )}&key=${encodeURIComponent(r.key)}`
        setIcsUrl(url)
      }
    }
    void go()
  }, [])
  const ref = React.useRef<InputRef>(null)
  const selectAndCopy = React.useCallback(() => {
    if (ref.current?.input) {
      ref.current?.select()
      ref.current?.setSelectionRange(0, 101024 /* arbitrary, large */)
      void navigator.clipboard.writeText(ref.current.input?.value)
      alert(
        intl.formatMessage({
          id: "planner.subscription.url-copied-to-clipboard",
        })
      )
    }
  }, [intl])
  return (
    <div className="mobile-side-menu-entry mobile-side-menu-subscribe">
      <div className="subscribe-to-calendar-mobile-link"
        onClick={createSubscription}
      >
      <CalendarOutlined
      >{

        }</CalendarOutlined>
      <span>
      {intl.formatMessage({ id: "mobile.subscribe-to-calendar-label" })}
</span></div>
      {icsUrl ? (
        <div className="subscribe-link">
          <Space.Compact
            style={{
              width: icsUrl ? 'calc(100vw - 120px)' : '28px',
              height: 28,
              marginTop: 1,
            }}
          >
            <Input.Password style={{ height: 32 }} ref={ref} value={icsUrl}></Input.Password>
            <Button
              icon={<CopyOutlined></CopyOutlined>}
              onClick={selectAndCopy}
            ></Button>
          </Space.Compact>
          <div className="subscribe-link-help">
            {intl.formatMessage({id: "mobile.subscribe-to-calendar-help"})}
          </div>
        </div>
      ) : null}
    </div>
  )
}

// Failed attempts:
// window.open(url)
// void navigator.clipboard.writeText(url)
// alert(
//   this.props.intl.formatMessage({
//     id: "planner.subscription.url-copied-to-clipboard",
//   })
// )

/* eslint-disable max-len */
const messages = {
  "roles.keyFiguresTitle": "Benutzerdefinierte Rollen",
  "roles.usageWarning":
    "Wenn diese Rolle gelöscht wird, verlieren alle Benutzer mit dieser Rollenzuweisung die entsprechenden Rechte. Die Löschung würde die folgende Anzahl von Benutzern betreffen:",
  "roles.heading": "Rollen",
  "roles.newButton": "Neue Rolle anlegen",
  "roles.delete": "Löschen",
  "roles.expand": "mehr",
  "roles.default": "Standard",
  "roles.addAllDefaultRoles": "Alle Standardrollen anlegen",
  "roles.addSingleDefaultRole": "Standardrolle anlegen",
  "roles.name": "Name",
  "roles.descr": "Beschreibung",
  "roles.userPermissions": "Benutzer Rechte",
  "roles.generalPermissions": "Allgemeine Rechte",
  "roles.permissions": "Rechte",
  "roles.error.editing": "Fehler",
  "roles.error.missingName": "Bitte einen Namen für die Rolle eingeben.",
  "roles.editRole": "Rolle bearbeiten",
  "roles.confirmDeleteHeader": "Rolle wirklich löschen?",
  "roles.customCount": "Anzahl selbstdefinierte Rollen",
  "roles.attrDiff": "Wert weicht vom Standard ab",
  "roles.diffFromDefault": 'Der Standardwert war "{defaultRoleValue}"',
  "permission.edit-clients": "Kunden bearbeiten",
  "permission.view-clients": "Kunden ansehen",
  "permission.edit-orgunit": "Organisationseinheit bearbeiten",
  "permission.view-activities": "Kalendereintragstypen ansehen",
  "permission.edit-activities": "Kalendereintragstypen bearbeiten",
  "permission.view-demand-planning": "Bedarfsplanung ansehen",
  "permission.edit-demand-planning": "Bedarfsplanung bearbeiten",
  "permission.view-qualifications": "Qualifikationen ansehen",
  "permission.edit-qualifications": "Qualifikationen bearbeiten",
  "permission.assign-qualifications": "Qualifikationen zuweisen",
  "permission.view-qualifications-assignments":
    "Qualifikationszuweisungen ansehen",
  "permission.view-autoplans": "Autoplan ansehen",
  "permission.edit-autoplans": "Autoplan bearbeiten",
  "permission.view-users+employees-master-data":
    "Mitarbeiter Stammdaten ansehen",
  "permission.edit-users+employees-master-data":
    "Mitarbeiter Stammdaten bearbeiten",
  "permission.view-own-master-data": "Eigene Stammdaten ansehen",
  "permission.edit-employee-leave-request": "Anfragen stellen",
  "permission.archive-users+employees": "Benutzer und Mitarbeiter archivieren",
  "permission.delete-users+employees": "Benutzer und Mitarbeiter löschen",
  "permission.archive+delete-employees": "Mitarbeiter archivieren und löschen",
  "permission.view-work-contracts": "Arbeitsverträge ansehen",
  "permission.edit-work-contracts": "Arbeitsverträge bearbeiten",
  "permission.edit-vacation-sheet": "Urlaubskonto bearbeiten",
  "permission.edit-lock-times": "Sperrzeiten bearbeiten",
  "permission.view-lock-times": "Sperrzeiten ansehen",
  "permission.edit-own-lock-times": "Eigene Sperrzeiten bearbeiten",
  "permission.view-own-lock-times": "Eigene Sperrzeiten ansehen",
  "permission.send-messages": "Nachrichten an Benutzer verschicken",
  "permission.edit-documents": "Dokumente bearbeiten",
  "permission.assign-roles": "Rollen zuweisen",
  "permission.edit-roles": "Rollen bearbeiten",
  "permission.view-role-assignments": "Rollenzuweisungen ansehen",
  "permission.view-settings": "Einstellungen ansehen",
  "permission.edit-settings": "Einstellungen bearbeiten",
  "permission.view-org-chart": "Organigramm ansehen",
  "permission.edit-org-chart": "Organigramm bearbeiten",
  "permission.view-planner": "Planer ansehen",
  "permission.show-balances-planner": "Salden im Planer anzeigen",
  "permission.view-own-planner": "Eigenen Planer ansehen",
  "permission.use-planner-activities": "Aktivitäten im Planer verwenden",
  "permission.use-protected-planner-activities":
    "Geschütze Aktivitäten im Planer verwenden",
  "permission.use-planer-work-schedules": "Arbeitspläne im Planer verwenden",
  "permission.view-events": "Events ansehen",
  "permission.edit-events": "Events bearbeiten",
  "permission.export-planning-data": "Export von Planungsdaten",
  "permission.import-external-data": "Import aus der Warenwirtschaft",
  "permission.edit-mail-reports": "Mailreporting bearbeiten",
  "permission.manage-suggestions": "Mitarbeiterwünsche verwalten",
  "permission.view-temp-assignments": "Verleihungen ansehen",
  "permission.edit-temp-assignments": "Verleihungen bearbeiten",
  "permission.view-re-assignments": "Stammabteilungen ansehen",
  "permission.edit-re-assignments": "Stammabteilungen bearbeiten",
  "permission.edit-planner": "Planer bearbeiten",
  "permission.change-password": "Passwort ändern",
  "permission.view-planbuildingblocks": "Planbausteine ansehen",
  "permission.edit-planbuildingblocks": "Planbausteine bearbeiten",
  "permission.view-pbbtemplates": "Planbausteine ansehen",
  "permission.edit-pbbtemplates": "Planbausteine bearbeiten",
  "permission.view-collectiveagreements": "Tarife ansehen",
  "permission.edit-collectiveagreements": "Tarife bearbeiten",
  "permission.edit-leave-account": "Urlaubskonto bearbeiten",
  "permission.view-leave-account": "Urlaubskonto ansehen",
  "permission.edit-work-hours-account": "Zeitkonto bearbeiten",
  "permission.view-work-hours-account": "Zeitkonto ansehen",
  "permission.view-own-leave-account": "Eigenes Urlaubskonto ansehen",
  "permission.view-own-work-hours-account": "Eigenes Zeitkonto ansehen",
  "permission.view-support-button": "Support-Ticket erstellen",
  "permission.view-user-reports": "Berichte ansehen",
  "permission.view-client-master-data": "Mandanten Stammdaten ansehen",
  "permission.view-colleague-planner": "Pläne der Kollegen ansehen",
  "permission.edit-own-logged-times": "Eigene Zeiterfassung bearbeiten",
  "permission.punch-in-to-log-times": "Eigene Arbeitszeit direkt erfassen",
  "permission.view-vacation-overview-details":
    "Anteilige Urlaubsberechnung anzeigen",
  "permission.edit-retroactive-limits":
    "Vergangenheitseinstellungen bearbeiten",
  "permission.ignore-retroactive-limits":
    "Vergangenheitseinstellungen ignorieren",
  "permission.use-planner-pool": "Schichtbörse verwenden",
}

export default messages

/* eslint-disable max-len */
const messages = {
  "users.autoplans": "Autopläne",
  "users.confirmDeactivate2fa":
    "Bitte bestätigen Sie die Deaktivierung der Zwei-Faktor-Authentisierung für den ausgewählten Benutzer mit Ihrem Passwort.",
  "users.tooltip.disable2fa": "Zweiten Faktor/Authenticator deaktivieren",
  "users.edit-username": "Benutzernamen ändern",
  "users.heading": "Mitarbeiterübersicht",
  "users.username": "Benutzername",
  "users.usernameAsMail": "Benutzername (Mailadresse)",
  "users.isAdmin": "Admin?",
  "users.optionalRegistrationInfos":
    "Für personalisierte E-Mails bitte die folgenden Daten angeben:",
  "users.registrationEntryDateInfo":
    "Ein Datum für die erste Versetzung angeben. Das heutige Datum wird als Default verwendet.",
  "users.select": "Auswählen",
  "users.forAll": "Alle ausgewählten",
  "users.name": "Name",
  "users.department": "Abteilung (OE)",
  "users.orgaIsDeleted": "(gelöscht)",
  "users.orgaIsArchived": "(archiviert)",
  "users.branch": "Filiale",
  "users.location": "Standort",
  "users.hasContract": "Vertrag",
  "users.hasAutoplan": "Autoplan",
  "users.qualifications": "Qualifikationen",
  "users.roles": "Rollen",
  "users.checkCol": "Ausgewählt",
  "users.rolesincontext": "Rollen für die gewählte Organisationseinheit",
  "users.active-login": "Aktiv, Login",
  "users.active-no-login": "Aktiv, kein Login",
  "users.deleted": "Gelöscht",
  "users.candidate": "Einladungsmail gesendet",
  "users.hidden": "Versteckt",
  "users.visible": "Sichtbar",
  "users.isLockedForPasswordReset": "Passwort-vergessen-Funktion gesperrt",
  "users.login": "Login",
  "users.client": "Mandant",
  "users.addUser": "Mitarbeiter anlegen",
  "users.addCandidateUser": "Benutzer einladen",
  "users.editUser": "Benutzer",
  "users.editUsername": "E-Mail-Adresse",
  "users.employee": "Mitarbeiter",
  "users.hide": "Benutzer nicht im Planer zeigen",
  "users.show": "Benutzer im Planer zeigen",
  "users.lock": "Login sperren",
  "users.unlock": "Login erlauben",
  "users.lockPasswordReset": "Passwort vergessen Funktion sperren",
  "users.unlockPasswordReset": "Passwort vergessen Funktion erlauben",
  "users.activate": "Aktivieren",
  "users.mailOnChange":
    "E-Mail versenden, damit der Benutzer ein neues Passwort erstellen kann?",
  "users.renameOnRestore": "Neuer Benutzername nach Wiederherstelltung",
  "users.renameOnRestoreHelp":
    "Der Benutzername/E-Mail des Benutzers wurde bereits wiederverwendet. Bennen Sie hier den zu wiederherstellenden Benutzer um.",
  "users.restoreFailuer": "Wiederherstellung fehlgeschlagen",
  "users.resendReactivate": "Neue Reaktivierung senden",
  "users.editCandidateUser": "Kandidat bearbeiten",
  "users.confirmDeleteHeader": "Benutzer wirklich löschen?",
  "users.confirmRestoreHeader": "Benutzer wirklich wiederherstellen?",
  "users.confirmLockHeader": "Benutzer wirklich sperren?",
  "users.confirmUnlockHeader": "Benutzer wirklich entsperren?",
  "users.confirmLockPasswordResetHeader":
    "Passwort vergessen Funktion des Benutzers wirklich sperren?",
  "users.confirmUnlockPasswordResetHeader":
    "Passwort vergessen Funktion des Benutzers wirklich entsperren?",
  "users.confirmActivateHeader": "Benutzer wirklich aktivieren?",
  "users.showConfirm.header":
    "E-Mail versenden, damit der Benutzer ein neues Passwort erstellen kann?",
  "users.tooltip.add": "Mitarbeiter anlegen",
  "users.tooltip.save": "Änderungen speichern.",
  "users.tooltip.delete": "Benutzer löschen.",
  "users.tooltip.restore": "Benutzer wiederherstellen.",
  "users.tooltip.hide": "Nicht im Planer zeigen.",
  "users.tooltip.show": "Im Planer zeigen.",
  "users.tooltip.lock": "Zugang sperren.",
  "users.tooltip.unlock": "Zugang freigeben",
  "users.tooltip.lockPasswordReset": "Passwort-vergessen-Funktion sperren.",
  "users.tooltip.unlockPasswordReset": "Passwort-vergessen-Funktion freigeben",
  "users.tooltip.activate": "Erste Benutzer Aktivierung.",
  "users.close": "Schließen",
  "users.add": "Anlegen",
  "users.createdBy": "Angelegt von",
  "users.createdAt": "Angelegt am",
  "users.expiresAt": "Läuft ab",
  "users.workHoursAccount.deleted-corrections": "Gelöschte Korrekturen",
  "users.account.add-relative-correction": "Relative Korrektur hinzufügen",
  "users.account.add-absolute-correction": "Absolute Korrektur hinzufügen",
  "users.workHoursAccount.deleted-absolute-correction":
    "Absolute Korrektur vom {date} durch {by}: {value} ({comment}); gelöscht von {deletedBy} am {deletionDate}",
  "users.workHoursAccount.deleted-relative-correction":
    "Relative Korrektur vom {date} durch {by}: {value} ({comment}); gelöscht von {deletedBy} am {deletionDate}",
  "users.account.enable-deletion": "Korrektur löschen",
  "users.workHoursAccount.hideDeletedCorrections":
    "Gelöschte Korrekturen verbergen",
  "users.workHoursAccount.showDeletedCorrections":
    "Gelöschte Korrekturen anzeigen",
  "users.workHoursAccount.dayRow.deleteCorrection": "löschen",
  "users.workHoursAccount.dayRow.confirmCorrectionDeletion":
    "Korrektur wirklich löschen?",
  "users.account.noContract":
    "Der Mitarbeiter hat keinen Arbeitsvertrag. Es können daher keine Daten angezeigt werden.",
  "users.workHoursAccount": "Zeitkonto",
  "users.workHoursAccount.selectYear": "Jahr auswählen",
  "users.workHoursAccount.cachedSum":
    "Diese Summe wird aus den gecachten Werten der Vorwochen gebildet, und kann daher von dem aktuellem Stand abweichen, sofern eine der vorherigen Wochen einen veralteten Stand hat.",
  "users.workHoursAccount.outdatedWeek":
    "Schraffiert dargestellte Kalenderwochen werden gerade neu berechnet und enthalten aktuell möglicherweise noch veraltete Werte.",
  "users.workHoursCorrection.title": "Zeitkonto: Korrekturbuchung",
  "users.workHoursCorrection.createButton": "Korrektur hinzufügen",
  "users.workHoursCorrection.closeButton": "Zeitkonto anzeigen",
  "users.workHoursCorrection.effectiveDate": "Gültigkeitsdatum",
  "users.workHoursCorrection.isSubtraction": "Zeit hinzufügen oder reduzieren",
  "users.workHoursCorrection.isAbsolute": "Absolute Anpassung",
  "users.workHoursCorrection.paytime": "Brutto",
  "users.workHoursCorrection.overtime": "Netto",
  "users.workHoursCorrection.comment": "Anmerkung",
  "users.workHoursCorrection.pause": "Pause",
  "users.workHoursCorrection.correction": "Korrektur",
  "users.workHoursCorrection.contract": "Vertrag",
  "users.workHoursCorrection.overtimeShort": "Netto",
  "users.workHoursCorrection.paytimeShort": "Brutto",
  "users.workHoursCorrection.overtimeDiffShort": "Diff Netto / AV",
  "users.workHoursCorrection.paytimeDiffShort": "Diff Brutto / AV",
  "users.workHoursCorrection.running": "Laufend",
  "users.workHoursCorrection.dateHeader": "Datum",
  "users.workHoursCorrection.PlanHeader": "Plan",
  "users.workHoursCorrection.SumDayHeader": "Summe Tag",
  "users.workHoursCorrection.SumRunningHeader": "Summe Woche Netto",
  "users.workHoursCorrection.absolute": "absolut",
  "users.workHoursCorrection.holidayCalculation": "Feiertag",
  "users.leaveAccount": "Urlaubskonto",
  "users.leaveAccount.cacheWarning":
    "Einige Urlaube werden noch berechnet und erscheinen möglicherweise noch nicht.",
  "users.leaveAccount.selectYear": "Jahr auswählen",
  "users.leaveAccount.yearlyLeaveAvailable": "Urlaubsanspruch",
  "users.leaveAccount.preYearRemains": "Resturlaub aus Vorjahr",
  "users.leaveAccount.yearlyLeaveTake": "Urlaub genommen",
  "users.leaveAccount.yearlyLeaveRemains": "Urlaub verbleibend",
  "users.leaveAccount.date": "Datum",
  "users.leaveAccount.action": "Aktion",
  "users.leaveAccount.leave": "Urlaub",
  "users.leaveAccount.paytime": "Brutto",
  "users.leaveAccount.overtime": "Netto",
  "users.leaveAccount.remainder": "verbleiben",
  "users.leaveAccount.yearBegin": "Jahresurlaubsanspruch",
  "users.leaveAccount.prevousRemains": "Resturlaub aus Vorjahr",
  "users.leaveAccount.contractChange": "Jahresurlaubsanspruch lt. Vertrag",
  "users.leaveAccount.leaveTaken": "Urlaub genommen",
  "users.leaveAccount.relativeCorretions": "Relative Korrekturen",
  "users.leaveAccount.absoluteCorrection": "Absolute Korrektur",
  "users.leaveCorrection.title": "Urlaubskonto: Korrekturbuchung",
  "users.leaveCorrection.createButton": "Korrektur hinzufügen",
  "users.leaveCorrection.effectiveDate": "Gültigkeitsdatum",
  "users.leaveCorrection.isSubtraction": "Tage hinzufügen oder reduzieren",
  "users.leaveCorrection.isAbsolute": "Absolute Anpassung",
  "users.corrections.isAbsolute.help":
    "Das Zeitkonto enthält exakt den angegebenen Wert.",
  "users.leaveCorrection.useHours": "Stunden eintragen",
  "users.leaveCorrections.isAbsolute.help":
    "Das Urlaubskonto enthält exakt den angegebenen Wert.",
  "users.leaveCorrection.leaveDays":
    "Urlaubstage (bis zu zwei Nachkommastellen)",
  "users.leaveCorrection.leaveHoursAndMinutes": "Urlaubsstunden",
  "users.leaveCorrection.tooManyDecimals": "Nur zwei Nachkommastellen erlaubt.",
  "users.leaveCorrection.comment": "Anmerkung",
  "users.leaveCorrection.absolute": "absolut",
  "users.leaveCorrection.wholeDays": "Nur ganze Tage",
  "users.leaveCorrection.below90": "Maximal 90 Tage",
  "users.roleassignments": "Rollen",
  "users.removeLocalRoles": "Lokale Rollen entfernen",
  "users.userroles": "Rollen des Benutzers",
  "users.localRoles": "Lokale Rollen für ",
  "users.remoteRoles": "Rollen in anderen Organisationseinheiten",
  "users.clientroles": "Rollen des Mandanten",
  "users.selectOrgunit": "Bitte eine Organisationseinheit auswählen.",
  "users.addType": "Art des Benutzers",
  "users.fullUser": "Mitarbeiter",
  "users.candidateUser": "Kandidat",
  Kandidat: "Kandidat",
  "users.invite.descr":
    "Ein eingeladener Benutzer kann erst für die Planung verwendet werden, wenn der Link aus der Einladungsmail besucht, und ein Passwort gesetzt worden ist.",
  "users.add.descr":
    "Ein direkt angelegter Benutzer kann sofort für die Planung verwendet werden. Der Benutzerzugang ist allerdings noch solange deaktiviert, bis die Reaktivierung mit einem neuen Passwort durchgeführt worden ist.",
  "users.qualificationAssignments": "Qualifikationen",
  "users.masterData": "Stammdaten",
  "users.entryDate": "Eintrittsdatum",
  "users.firstname": "Vorname",
  "users.lastname": "Nachname",
  "users.prefix": "Titel",
  "users.abbrv": "Abkürzung",
  "users.gender": "Geschlecht",
  "users.persnr": "Personalnummer",
  "users.birthday": "Geburtsdatum",
  "users.contact": "Kontaktdaten",
  "users.str": "Straße",
  "users.city": "Stadt",
  "users.postal": "PLZ",
  "users.country": "Land",
  "users.tel": "Telefon",
  "users.mobile": "Mobil",
  "users.importkey": "Importschlüssel",
  "users.photo": "Foto",
  "users.male": "männl.",
  "users.female": "weibl.",
  "users.na": "Keine Angabe",
  "users.status": "Status",
  "users.inactive": "Deaktiviert",
  "users.reactivated": "Reaktiviert, Mail gesendet",
  "users.unknown": "Unbekannter Benutzer Status",
  "users.invited": "Eingeladen, Mail gesendet",
  "users.managed": "Verwaltet",
  "users.modal.warn": "Ungespeicherte Änderungen verwerfen?",
  "users.edit.empty": "Keine Rechte um weitere Mitarbeiterdaten anzuzeigen.",
  "users.contracts.heading": "Arbeitsverträge",
  "users.addContract": "Neuer Arbeitsvertrag",
  "users.contracts.action": "Aktion",
  "users.contracts.weeklyhours": "Wöchentliche Arbeitsstunden",
  "users.contracts.yearlyvacationdays": "Jährliche Urlaubstage",
  "users.contracts.collectiveAgreement": "Basistarif",
  "users.contracts.modelWeekHeader": "Musterwoche",
  "users.contracts.modelWeekText":
    'Geben Sie hier die regelmäßigen Arbeitszeiten ein, falls Sie bei den Berechnungseinstellungen für Urlaub, Krankheit o.ä. die Option "Musterwoche" benutzten wollen.',
  "users.contracts.hoursMo": "Montag",
  "users.contracts.hoursTu": "Dienstag",
  "users.contracts.hoursWe": "Mittwoch",
  "users.contracts.hoursTh": "Donnerstag",
  "users.contracts.hoursFr": "Freitag",
  "users.contracts.hoursSa": "Samstag",
  "users.contracts.hoursSu": "Sonntag",
  "users.contracts.vacationDaysFirstYear": "Urlaubsanspruch im ersten Jahr",
  "users.contracts.startVacation": "Beginn des Jahresurlaubs",
  "users.contracts.startVacation.base": "Grundeinstellungen",
  "users.contracts.startVacation.january": "01.01. eines Jahres",
  "users.contracts.startVacation.entry": "Eintrittsdatum",
  "users.contracts.vacationDays": "jährliche Urlaubstage",
  "users.contracts.hiatus":
    "Unterbrechung des laufenden Angestelltenverhältnisses",
  "users.contracts.hideUser": "Mitarbeiter nicht anzeigen",
  "users.contracts.validFrom": "Gültig ab",
  "users.contracts.validUntil": "Gültig bis",
  "users.contracts.updatedAt": "Bearbeitungsdatum",
  "users.contracts.edit": "Arbeitsvertrag",
  "users.contracts.invalidContract": "Ungültiger Vertrag",
  "users.contracts.overrideweeklyhours":
    "Wöchentliche Arbeitsstunden überschreiben?",
  "users.contracts.overrideyearlyvacationdays":
    "Jährliche Urlaubstage überschreiben?",
  "users.contracts.overrideyellowlimit":
    "Schwelle für gelbe Ampel überschreiben?",
  "users.contracts.yellowlimit": "Schwelle für gelbe Ampel (Überstunden)",
  "users.contracts.yellowlimitnegative":
    "Schwelle für gelbe Ampel (Unterstunden)",
  "users.contracts.overrideredlimit": "Schwelle für rote Ampel überschreiben?",
  "users.contracts.redlimit": "Schwelle für rote Ampel (Überstunden)",
  "users.contracts.redlimitnegative": "Schwelle für rote Ampel (Unterstunden)",
  "users.contracts.overridesHeader": "Tarifanpassungen",
  "users.contracts.overridesText":
    "Hier können einzelne Werte der Tarife überschrieben werden.",
  "users.contracts.regularworkdaysHeader":
    "Regelmäßige Arbeitstage für Berechnungen",
  "users.contracts.regularworkdaysText":
    "Die regelmäßigen Arbeitstage auswählen oder für Berechnungen deaktivieren.",
  "users.contracts.or": "oder",
  "users.contracts.regularworkdays": "Regelmäßige Arbeitstage",
  "users.contracts.neveruserregularworkdaysforcalculations":
    "Nie die regelmäßigen Arbeitstage für Berechnungen verwenden",
  "users.contracts.showObsolete": "Veraltete Versionen anzeigen",
  "users.contracts.caWeeklyHours": "Tarif wöchentliche Arbeitsstunden: ",
  "users.contracts.caVacationDays": "Tarif jährliche Urlaubstage: ",
  "selected collective agreement requires defined regular workdays":
    "Der ausgewählte Tarif benötigt eine Angabe der regelmäßigen Arbeitstage.",
  "selected collective agreement has no effective settings for this contract":
    "Der ausgewählte Tarif kann für das gesetzte Startdatum des Arbeitsvertrages keine Werte für die Berechnugen liefern. Überprüfen Sie das Tarif Startdatum.",
  "users.tempassignments.heading": "Verleihungen",
  "users.tempassignments.edit": "Verleihung",
  "users.tempassignments.toOrgUnit": "Zur Organisationseinheit",
  "users.addTempAssignment": "Verleihung hinzufügen",
  "users.reassignments.heading": "Stammabteilung",
  "users.reassignments.edit": "Versetzung",
  "users.reassignment.from": "Von",
  "users.reassignment.to": "Nach",
  "users.reassignment.when": "Wann",
  "users.reassignment.to.label": "Nach (Abteilung = Versetzungsziel)",
  "users.reassignment.when.label": "Wann (Zeitpunkt der Versetzung)",
  "users.reassignment.deleteConfirm": "Diese Versetzung wirklich löschen?",
  "users.reassignments.futureReassignment": "Wird versetzt am: ",
  "users.reassignments.futureTo": " nach: ",
  "users.addReassignment": "Versetzung hinzufügen",
  "users.addUserQualification": "Benutzer Qualifikation zuweisen",
  "users.showQualifications": "Qualifikationen anzeigen",
  "users.qualifications.qualification": "Qualifikation",
  "users.qualifications.effectiveDate": "Gültig ab",
  "users.qualifications.isPrimary": "Ist primäre Qualifikation?",
  "users.qualifications.primaryEffectiveDate": "Primär ab",
  "users.qualifications.primaryOverlapHeader":
    "Warnung: Primär ab Überschneidung!",
  "users.qualifications.primaryOverlapText":
    "Das gewählte Primär ab Datum überschneidet sich mit einer anderen Benutzer Qualifikation. Die aktuell bearbeitete Benutzer Qualifikation wird Vorrang haben, sobald sie gespeichert wird.",
  "users.qualifications.edit": "Qualifikation",
  "users.qualifications.invalid": "Ungültig",
  "users.qualifications.obsolete": "Veraltete Version",
  "users.qualifications.error.missingEffectiveDate":
    "Bitte ein Datum auswählen.",
  "users.qualifications.error.missingQualification":
    "Bitte eine Qualifikation auswählen.",
  "users.qualifications.error.missingPrimaryEffectiveDate":
    "Bitte ein Datum auswählen.",
  "users.orgunit": "Organisationseinheit",
  "users.showDescendants": "Unterabteilungen anzeigen",
  "users.showHidden": "Versteckte anzeigen",
  "users.showDeleted": "Gelöschte anzeigen",
  "users.unassignedUsers": "Aktuell nicht zugeordnet",
  "users.misassignedUsers": "Fehlerhaft zugeordnete Benutzer",
  "users.settings.heading": "Konfiguration",
  "users.error": "Fehler",
  "users.error.missingField": "Bitte einen Wert eingeben",
  "users.error.dateInPast": "Das Datum darf nicht in der Vergangenheit liegen.",
  "users.error.editing":
    "Fehler im Formular. Bitte überprüfen Sie die markierten Felder.",
  "users.error.username": "Der Benutzername ist nicht verfügbar.",
  "users.error.adding": "Benutzer konnte nicht angelegt werden.",
  "users.error.missingUsername": "Bitte einen Benutzername eintragen.",
  "users.error.wrongEmail":
    "Der Benutzername muss eine gültige E-Mail-Adresse sein.",
  "users.error.emailInUse": "Der Benutzername ist bereits in Verwendung.",
  "users.error.abbrvTooLong": "Die Abkürzung ist zu lang.",
  "users.error.roleassigment": "Fehler bei der Rollenzuweisung",
  "users.error.missingContext":
    "Kein Kunde oder keine Organisationseinheit ausgewählt.",
  "users.contracts.error.missingWeeklyhours":
    "Bitte die Wochenarbeitsszeit eintragen.",
  "users.contracts.error.missingYearlyvacationdays":
    "Bitte den Urlaubsanspruch eintragen.",
  "users.contracts.error.missingVacationDaysFirstYear":
    "Bitte den Urlaubsanspruch im ersten Jahr eintragen.",
  "users.contracts.error.missingStartVacation":
    "Bitte den Beginn des Jahresurlaubs eintragen.",
  "users.contracts.error.missingVacationDays":
    "Bitte geben Sie den jährlichen Urlaub an.",
  "users.contracts.error.missingValidFrom":
    "Bitte das gültig ab Datum eintragen.",
  "users.contracts.error.or": "Bitte eine der Optionen auswählen.",
  "users.contracts.error.required": "Ein Wert muss angegeben werden.",
  "users.tempassignments.error.untilBeforeFrom":
    'Das "Gültig bis" Datum muss nach dem "Gültig ab" Datum sein.',
  "users.adminConditionHeader": "Admin Rolle",
  "users.adminConditionText":
    "Wenn eine Benutzerin keinem Mandaten zugewiesen ist, gilt sie als Administrator und hat damit Zugriff auf alle Mandanten!",
  "candidate.resend": "Neue Einladung schicken",
  "candidate.refresh": "Ablaufdatum erneuern",
  "candidate.expire": "Einladung zurückziehen",
  "registration.heading": "Registrierung",
  "registration.error": "Fehler",
  "registration.password": "Passwort",
  "registration.password-repeat": "Passwort (wiederholen)",
  "registration.password-weak": "Passwort zu schwach.",
  "registration.password-unequal": "Passwörter stimmen nicht überein.",
  "registration.decodeTokenError":
    "Ungültige URL. Bitte überprüfen Sie den Link aus der Registrierungsmail.",
  "registration.registrationError":
    'Diese Einladung ist evtl. abgelaufen. Bitte fordern Sie eine neue Mail mit einem Passwort-setzen Link an (z.B. mit der "Passwort vergessen" Funktion des Login-Formulars).',
  "registration.MissingCandidate":
    'Diese Einladung ist evtl. abgelaufen. Bitte fordern Sie eine neue Mail mit einem Passwort-setzen Link an (z.B. mit der "Passwort vergessen" Funktion des Login-Formulars).',
  "change.changePassword": "Passwort ändern",
  "change.oldPassword": "Altes Passwort",
  "change.newPassword": "Neues Passwort",
  "change.newPasswordRepeat": "Neues Passwort (wiederholen)",
  "A problem with the password occurred":
    "Es gab ein Problem beim Ändern des Passwortes",
  incorrectPassword: "Das angegebene Password ist inkorrekt",
  "password-change-success": "Das Passwort wurde geändert.",
  userNotFound: "Der Benutzer konnte nicht gefunden werden",
  "userNavigation.box.title": "Benutzer suchen",
  "users.workHoursAccount.noData":
    "Keine Daten für den gewählten Zeitraum vorhanden. Der Mitarbeiter hat möglicherweise keinen aktiven Vertrag oder Versetzung in diesem Zeitraum.",
  "users.workHoursAccount.break": "Pause",
  "users.workHoursAccount.breaks": "Pausen",
  "users.workHoursAccount.weekNr": "KW",
  "users.workHoursAccount.weekStart": "Start",
  "users.workHoursAccount.weekEnd": "Ende",
  "users.workHoursAccount.weekUntil": "bis",
  "users.workHoursAccount.overtimeLong": "Netto",
  "users.workHoursAccount.paytimeLong": "Brutto",
  "users.workHoursAccount.dayHeader.day": "Datum",
  "users.workHoursAccount.dayHeader.details": "Beschreibung",
  "users.workHoursAccount.dayHeader.plan": "Geplant",
  "users.workHoursAccount.dayHeader.log": "Erfasst (Ist) ",
  "users.workHoursAccount.dayHeader.plannedtime": "Plan-Zeit",
  "users.workHoursAccount.dayHeader.loggedtime": "Ist-Zeit",
  "users.workHoursAccount.dayHeader.bonus": "Zuschlag",
  "users.workHoursAccount.dayHeader.bonuses": "Zuschläge",
  "users.workHoursAccount.dayHeader.total": "Saldo",
  "users.workHoursAccount.dayHeader.segment": "Beschreibung",
  "users.workHoursAccount.dayHeader.overtime": "Netto",
  "users.workHoursAccount.dayHeader.paytime": "Brutto",
  "users.workHoursAccount.dayHeader.weekdiff": "Wochen-",
  "users.workHoursAccount.dayHeader.diff": "Differenz",
  "users.workHoursAccount.dayHeader.contracttime": "Vertrag",
  "users.workHoursAccount.dayHeader.loggedOvertime": "Netto IST",
  "users.workHoursAccount.dayHeader.loggedPaytime": "Brutto IST",
  "users.workHoursAccount.dayHeader.correction": "Korrektur!",
  "users.workHoursAccount.bothCorrections": "Abs. und Rel.",
  "users.workHoursAccount.relCorrections": "Relativ",
  "users.workHoursAccount.absCorrections": "Absolut",
  "users.workHoursAccount.dayHeader.paytimeDiff": "Diff Brutto / AV",
  "users.workHoursAccount.dayHeader.planDiff": "Diff Plan / AV",
  "users.workHoursAccount.dayHeader.loggedDiff": "Diff Ist / AV",
  "users.workHoursAccount.dayHeader.loggedSaldo": "Saldo", // was "Saldo Ist",
  "users.workHoursAccount.dayHeader.paytimeWeek": "Brutto kum.",
  "users.workHoursAccount.dayHeader.overtimeWeek": "Netto kum.",
  "users.workHoursAccount.dayHeader.workWeek": "Geleistet",
  "users.workHoursAccount.dayHeader.toworkWeek": "Zu leisten",
  "users.workHoursAccount.dayHeader.diffWeek": "Wochensaldo", // was Saldo Ist
  "users.workHoursAccount.dayHeader.overtimeTotal": "Saldo Netto",
  "users.workHoursAccount.dayHeader.paytimeTotal": "Saldo Brutto",
  "users.workHoursAccount.dayRow.absCorrection": "Absolute Korrektur",
  "users.workHoursAccount.dayRow.relCorrection": "Relative Korrekturen",
  "usernameEdit.sendInfoMail": "Sende Mail über Änderung?",
  "usernameEdit.error.invalidEmail":
    "Bitte eine gültige E-Mail-Adresse eingeben.",
  "usernameEdit.change": "Benutzername ändern",
  "Trying to update invalid user for userId!":
    "Der aktuelle Benutzer scheint ungültig zu sein!",
  alreadyExistsError: "Fehler: Bereits vergeben",
  "The username is already in use!":
    "Der gewählte Benutzername wird bereits verwendet",
  "Username changed.": "Benutzername wurde geändert!",
  "prefix.dr": "Dr.",
  "prefix.ma": "M.A.",
  "prefix.masters": "Magister",
  "prefix.msc": "M.Sc.",
  "prefix.pharm": "Dipl. Pharm.",
  "users.exceededLicenseWarning":
    "Das Lizenzvolumen ist überschritten, oder würde mit dieser Aktion überschritten werden. Sie können dennoch einen neuen Benutzer anlegen oder einladen. Dadurch können aber weitere Lizenzkosten anfallen.",
  "users.lowLicenseWarning":
    "Es sind nur noch {remainingLicenses} Lizenzen verfügbar.",
  "users.licenseText":
    "Sie verfügen noch über ausreichende Lizenzen um einen neuen Benutzer anzulegen oder einzuladen.",
  "users.warning": "Hinweis",
  "page.pwchange.title": "Ändern Sie Ihr Passwort",
  "page.pwchange.description":
    "Bitte geben Sie das bisherige Passwort als Bestätigung an.",
  "pages.registration.title": "Willkommen bei Mina.Works!",
  "pages.registration.description":
    "Bitte setzen Sie das Passwort, dass Sie künftig für mina.works verwenden möchten",
  username: "Benutzername",
  save: "Speichern",
  cancel: "Abbrechen",
  Edit: "Bearbeiten",
  "form.error": "Die Daten konnten nicht gespeichert werden.",
  "users.starting": "ab",
}

export default messages

const messages = {
  "hourlyAnalysisReport.title": "Stundenauswertung",
  "hourlyAnalysisReport.generateReport": "Bericht erstellen",
  "hourlyAnalysisReport.generateXLSXReport": "Bericht erstellen (XLSX)",
  "hourlyAnalysisReport.type": "Typ",
  "hourlyAnalysisReport.from": "Von",
  "hourlyAnalysisReport.to": "Bis",
  "hourlyAnalysisReport.Date": "Datum",
  "hourlyAnalysisReport.Week": "Woche",
  "hourlyAnalysisReport.Month": "Monat",
  "hourlyAnalysisReport.Year": "Jahr",
}

export default messages

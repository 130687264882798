const messages = {
  "hourlyAnalysisReport.title": "Hourly analysis",
  "hourlyAnalysisReport.generateReport": "Generate report",
  "hourlyAnalysisReport.generateXLSXReport": "Generate report (XLSX)",
  "hourlyAnalysisReport.type": "Type",
  "hourlyAnalysisReport.from": "From",
  "hourlyAnalysisReport.to": "To",
  "hourlyAnalysisReport.Date": "Date",
  "hourlyAnalysisReport.Week": "Week",
  "hourlyAnalysisReport.Month": "Month",
  "hourlyAnalysisReport.Year": "Year",
}

export default messages

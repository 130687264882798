// TODO: These mappings should be configuration, and ideally be defined
//       declaratively with the menu anyway!

export const APPMODULETOURL = {
  home: "/",
  clientManager: "/clients",
  userManager: "/users",
  orgChart: "/orgchart",
  authorisation: "/authorisation",
  vacationCalculation: "/vacationcalculation",
  settings: "/settings",
  processManager: "/processManager",
  qualifications: "/qualifications",
  calendarentrytypes: "/calendarentrytypes",
  pbbTemplates: "/pbbtemplates",
  planner: "/planner",
  autoplan: "/autoplan",
  dayplanner: "/dayplanner",
  weekplanner: "/weekplanner",
  calendar: "/calendar",
  collectiveAgreementsManager: "/collectiveagreements",
  clientfeatureconfiguration: "/clientfeatureconfiguration",
  calculationProfileManager: "/calculationprofile",
  userData: "/userdata",
  holidayUpload: "/holidayupload",
  timeSheet: "/timesheet",
  timeSheetBonuses: "/timesheetbonuses",
  weeklySchedule: "/weeklyschedule",
  absenceOverview: "/absenceoverview",
  vacationList: "/vacationlist",
  vacationSheet: "/vacationsheet",
  yearSummary: "/yearsummary",
  calculationDetails: "/calculationdetails",
  hourlyAnalysis: "/hourlyanalysis",
  adminAccessManager: "/adminaccessmanager",
  infoMessageManager: "/infomessagemanager",
  packageoverview: "/packageoverview",
  telemetry: "/telemetry",
  ailog: "/ailog",
  clientStats: "/clientstats",
  otpsetup: "/otpsetup",
}

export const URLENDTOAPPMODULE = {
  clients: "clientManager",
  users: "userManager",
  processManager: "processManager",
  orgchart: "orgChart",
  authorisation: "authorisation",
  vacationcalculation: "vacationCalculation",
  settings: "settings",
  qualifications: "qualifications",
  calendar: "calendar",
  planner: "planner",
  dayplanner: "dayplanner",
  weekplanner: "weekplanner",
  autoplan: "autoplan",
  calendarentrytypes: "calendarentrytypes",
  pbbtemplates: "pbbTemplates",
  collectiveagreements: "collectiveAgreementsManager",
  calculationprofile: "calculationProfileManager",
  clientfeatureconfiguration: "clientfeatureconfiguration",
  userdata: "userData",
  holidayupload: "holidayUpload",
  timesheet: "timeSheet",
  timesheetbonuses: "timeSheetBonuses",
  weeklyschedule: "weeklySchedule",
  absenceoverview: "absenceOverview",
  vacationlist: "vacationList",
  vacationsheet: "vacationSheet",
  yearsummary: "yearSummary",
  calculationdetails: "calculationDetails",
  hourlyanalysis: "hourlyAnalysis",
  adminaccessmanager: "adminAccessManager",
  infomessagemanager: "infoMessageManager",
  packageoverview: "packageoverview",
  telemetry: "telemetry",
  ailog: "ailog",
  clientstats: "clientStats",
  otpsetup: "otpsetup",
}

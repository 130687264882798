import * as React from "react"
import DepartmentWidget from "../../../../shared/DepartmentWidget/DepartmentWidget"
import type OrganisationalUnit from "../../models/OrganisationalUnit"
import { useIntl } from "react-intl"

export interface Props {
  /**
   * Note that there are two "variants" for this
   * parameter:
   * The old version just provides a flat list of
   * departments (id, name, maybe color); if a
   * hierarchy is provided and is supposed to be
   * used, the deciding factor is that **children
   * must never be undefined**. If there are no
   * child nodes, and a hierarchy is assumed,
   * children must be an empty array.
   */
  disabled?: boolean
  roots: OrganisationalUnit[]
  selectedId?: string
  defaultDepartmentName?: string
  onSelect: (id?: string) => void
}

export default function EntryDepartmentWidget({
  disabled,
  roots,
  selectedId,
  onSelect,
  defaultDepartmentName,
}: Props): JSX.Element | null {
  const intl = useIntl()
  const selectCallback = React.useCallback(
    (d: { getId: () => string | undefined } | undefined) => {
      const id: string | undefined = d?.getId()
      return onSelect(d ? id : undefined)
    },
    [onSelect]
  )
  const filteredRoots = roots.filter(r => r.isRoot())
  const selectedNode = getSelectedDepartment(filteredRoots, selectedId)
  return (
    <DepartmentWidget
      disabled={disabled}
      root={filteredRoots}
      selected={selectedNode?.isDisabled() ? undefined : selectedNode}
      selectCallback={selectCallback}
      allowClear={true}
      placeholder={
        defaultDepartmentName ??
        intl.formatMessage({
          id: "choose-dept",
        })
      }
    ></DepartmentWidget>
  )
}

function getSelectedDepartment(
  nodes: OrganisationalUnit[],
  selectedId?: string
): OrganisationalUnit | undefined {
  if (nodes.length !== 0 && selectedId !== undefined) {
    for (const n of nodes) {
      if (`${n.getId()}` === `${selectedId}`) {
        return n
      }
      const recResult = getSelectedDepartment(n.getChildren() ?? [], selectedId)
      if (recResult !== undefined) {
        return recResult
      }
    }
  }
  return undefined
}

import {
  CloseOutlined,
  DesktopOutlined,
  LogoutOutlined,
} from "@ant-design/icons"
import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { Settings } from "../utils/SettingsPanel"
import SettingsPanel from "../utils/SettingsPanel"
import { usePermissionsForVisibility } from "../hooks/usePermissionsForVisibility"
import MobileSubscribeLink from "./MobileSubscribeLink"

export interface Props {
  /**
   * This is a controlled component - the menu is opened
   * "from the outside":
   */
  open: boolean
  close: () => void

  displayModule?: string
  setDisplayModule: (s: string) => void

  logout: () => void

  vacationRequestsAvailable: boolean

  username: string

  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

export default function MobileSideMenu(props: Props): JSX.Element | null {
  const intl = useIntl()
  usePermissionsForVisibility({ username: props.username })
  const [cls, setCls] = React.useState<string>(
    "mobile-side-menu side-menu-closed"
  )
  React.useEffect(() => {
    if (props.open) {
      setCls("mobile-side-menu side-menu-open")
    } else {
      setTimeout(() => setCls("mobile-side-menu side-menu-closed"), 300)
      setCls("mobile-side-menu side-menu-closing")
    }
  }, [props.open])
  return (
    <div className={cls}>
      <div className="menu-content">
        <div className="mobile-side-menu-header">
          <img
            alt="mina.works"
            src="mina-works-logo-blue.svg"
            className="mobile-logo"
            style={{ cursor: "pointer" }}
          ></img>
          <CloseOutlined
            onClick={props.close}
            className="mobile-menu-button"
          ></CloseOutlined>
        </div>
        <div className="mobile-side-menu-routes">
          <div
            className="mobile-side-menu-entry desktop-link"
            onClick={() => {
              window.document.location = "/"
            }}
          >
            <DesktopOutlined /> {intl.formatMessage({ id: "desktop-link" })}
          </div>
          <SettingsPanel
            saveUserSettings={props.saveUserSettings}
            loadUserSettings={props.loadUserSettings}
          ></SettingsPanel>
          <MobileSubscribeLink></MobileSubscribeLink>
        </div>
        <div
          className="mobile-side-menu-entry mobile-side-menu-logout"
          onClick={props.logout}
        >
          <LogoutOutlined></LogoutOutlined>{" "}
          <div className="mobile-logout-content">
            <FormattedMessage id="Logout"></FormattedMessage>
            <div className="mobile-username">{props.username}</div>
          </div>
        </div>
      </div>
      <div className="mobile-side-menu-backdrop" onClick={props.close}>
        &nbsp;
      </div>
    </div>
  )
}

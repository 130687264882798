/* eslint-disable react-refresh/only-export-components */
import React, { Suspense } from "react"
import { Card, Row, Col, Spin } from "antd"

import GetStarted from "../GetStarted"
import RegistrationFormContainer from "../../modules/userManager/containers/RegistrationFormContainer"
// import PasswordChangeContainer from "../../modules/userManager/containers/PasswordChangeContainer"
import HolidayUpload from "../../modules/settings/lib/holidays/HolidayUpload"
import Mobile from "./Mobile"
import Desktop from "./Desktop"
import App from "./App"
import "./Scaffold.css"

const Fallback = (): JSX.Element => (
  <div
    style={{
      height: "calc(100vh - 200px)", // optimized for admin users!
      width: "100vw",
      paddingTop: "calc(50vh - 125px)",
    }}
  >
    <Spin size="large" style={{ margin: "auto", display: "block" }}></Spin>
  </div>
)

const AILogAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/AILogAppWrapper")
)

const OTPSetupAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/OTPSetupAppWrapper")
)

const PlannerAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/PlannerAppWrapper")
)

const TelemetrySettingsAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/TelemetrySettingsAppWrapper")
)

const UserManagerContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/UserManagerContainerWrapper")
)

const OrgChartContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/OrgChartContainerWrapper")
)

const LoggedDayManagerAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/LoggedDayManagerAppWrapper")
)

const ProcessManagerAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/ProcessManagerAppWrapper")
)

const RoleManagerContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/RoleManagerContainerWrapper")
)

const QualificationManagerContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/QualificationManagerContainerWrapper")
)

const CetManagerContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/CetManagerContainerWrapper")
)

const PBBTemplateManagerContainerWrapperLazy = React.lazy(
  (): any => import("./wrappers/PBBTemplateManagerContainerWrapper")
)

const TimeSheetBonusesWrapperLazy = React.lazy(
  (): any => import("./wrappers/TimeSheetBonusesWrapper")
)

const TimeSheetWrapperLazy = React.lazy(
  (): any => import("./wrappers/TimeSheetWrapper")
)

const AbsenceOverviewWrapperLazy = React.lazy(
  (): any => import("./wrappers/AbsenceOverviewWrapper")
)

const WeeklyScheduleWrapperLazy = React.lazy(
  (): any => import("./wrappers/WeeklyScheduleWrapper")
)

const VacationListWrapperLazy = React.lazy(
  (): any => import("./wrappers/VacationListWrapper")
)

const VacationSheetWrapperLazy = React.lazy(
  (): any => import("./wrappers/VacationSheetWrapper")
)

const YearSummaryWrapperLazy = React.lazy(
  (): any => import("./wrappers/YearSummaryReportWrapper")
)

const CalculationDetailsWrapperLazy = React.lazy(
  (): any => import("./wrappers/CalculationDetailsWrapper")
)

const HourlyAnalysisWrapperLazy = React.lazy(
  (): any => import("./wrappers/HourlyAnalysisWrapper")
)

const LicenseStatsWrapperLazy = React.lazy(
  (): any => import("./wrappers/LicenseStatsWrapper")
)

const AdminAccessAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/AdminAccessAppWrapper")
)
const InfoMessageAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/InfoMessageAppWrapper")
)
const PackageOverviewAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/PackageOverviewAppWrapper")
)

const CalculationProfileAppWrapperLazy = React.lazy(
  (): any => import("./wrappers/CalculationProfileAppWrapper")
)

const ClientManagerContainerLazy = React.lazy(
  (): any =>
    import("../../modules/clientManager/containers/ClientManagerContainer")
)

const UserDataViewContainerLazy = React.lazy(
  (): any =>
    import("../../modules/userManager/containers/UserDataViewContainer")
)

const PasswordChangeContainerLazy = React.lazy(
  (): any => import("./wrappers/PasswordChangeContainerWrapper")
)

const ClientFeatureConfigurationContainerLazy = React.lazy(
  (): any => import("./wrappers/ClientFeatureConfigurationAppWrapper")
)

const desktopChildren = [
  {
    path: "clientfeatureconfiguration",
    element: (
      <Suspense fallback={<Fallback />}>
        <ClientFeatureConfigurationContainerLazy />
      </Suspense>
    ),
  },
  {
    path: "telemetry",
    element: (
      <Suspense fallback={<Fallback />}>
        <TelemetrySettingsAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "dayloggerManager",
    element: (
      <Suspense fallback={<Fallback />}>
        <LoggedDayManagerAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "processManager",
    element: (
      <Suspense fallback={<Fallback />}>
        <ProcessManagerAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "otpsetup",
    element: (
      <Suspense fallback={<Fallback />}>
        <OTPSetupAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "ailog",
    element: (
      <Suspense fallback={<Fallback />}>
        <AILogAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "planner",
    element: (
      <Suspense fallback={<Fallback />}>
        <PlannerAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "clients",
    element: (
      <Suspense fallback={<Fallback />}>
        <ClientManagerContainerLazy />
      </Suspense>
    ),
  },
  {
    path: "orgchart",
    element: (
      <Suspense fallback={<Fallback />}>
        <OrgChartContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "users",
    element: (
      <Suspense fallback={<Fallback />}>
        <UserManagerContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "userdata",
    element: (
      <Suspense fallback={<Fallback />}>
        <UserDataViewContainerLazy />
      </Suspense>
    ),
  },
  {
    path: "authorisation",
    element: (
      <Suspense fallback={<Fallback />}>
        <RoleManagerContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    // TODO: LAZY LOAD THIS
    path: "changepassword",
    element: (
      <Suspense fallback={<Fallback />}>
        <PasswordChangeContainerLazy />,
      </Suspense>
    ),
  },
  {
    path: "qualifications",
    element: (
      <Suspense fallback={<Fallback />}>
        <QualificationManagerContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "calendarentrytypes",
    element: (
      <Suspense fallback={<Fallback />}>
        <CetManagerContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "pbbtemplates",
    element: (
      <Suspense fallback={<Fallback />}>
        <PBBTemplateManagerContainerWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "calculationprofile",
    element: (
      <Suspense fallback={<Fallback />}>
        <CalculationProfileAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "holidayupload",
    element: (
      <Card style={{ border: "none" }}>
        <HolidayUpload />
      </Card>
    ),
  },
  {
    path: "timesheet",
    element: (
      <Suspense fallback={<Fallback />}>
        <TimeSheetWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "timesheetbonuses",
    element: (
      <Suspense fallback={<Fallback />}>
        <TimeSheetBonusesWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "weeklyschedule",
    element: (
      <Suspense fallback={<Fallback />}>
        <WeeklyScheduleWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "absenceoverview",
    element: (
      <Suspense fallback={<Fallback />}>
        <AbsenceOverviewWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "vacationlist",
    element: (
      <Suspense fallback={<Fallback />}>
        <VacationListWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "vacationsheet",
    element: (
      <Suspense fallback={<Fallback />}>
        <VacationSheetWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "yearsummary",
    element: (
      <Suspense fallback={<Fallback />}>
        <YearSummaryWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "calculationdetails",
    element: (
      <Suspense fallback={<Fallback />}>
        <CalculationDetailsWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "hourlyanalysis",
    element: (
      <Suspense fallback={<Fallback />}>
        <HourlyAnalysisWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "clientstats",
    element: (
      <Suspense fallback={<Fallback />}>
        <LicenseStatsWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "packageoverview",
    element: (
      <Suspense fallback={<Fallback />}>
        <PackageOverviewAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "infoMessageManager",
    element: (
      <Suspense fallback={<Fallback />}>
        <InfoMessageAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    path: "adminAccessManager",
    element: (
      <Suspense fallback={<Fallback />}>
        <AdminAccessAppWrapperLazy />
      </Suspense>
    ),
  },
  {
    // default
    path: "",
    element: (
      <Card className="mainCard">
        <GetStarted />
        <Row>
          <Col xs={{ span: 22, offset: 1 }} xl={{ span: 12, offset: 6 }}></Col>
        </Row>
      </Card>
    ),
  },
]

const routes = [
  {
    path: "/registration",
    element: (
      <Card className="centralContent">
        <RegistrationFormContainer {...({} as any)} />
      </Card>
    ),
  },
  {
    path: "/",
    element: <App />,
    errorElement: (
      <div style={{ padding: 20 }}>
        <p>Ein unbekannter Fehler ist aufgetreten.</p>

        <p>
          Bitte prüfen Sie, ob die Adresse, die sie im Browser eingegeben haben,
          korrekt ist.
        </p>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          style={{ fontSize: 16, height: 32 }}
          onClick={() => window.document.location.reload()}
        >
          Neu laden/Reload
        </button>

        <p>
          <a href="/">mina.works Startseite / Home</a>
        </p>

        <p>EN: An unknown error has occurred. Please check the URL.</p>
      </div>
    ),
    children: [
      {
        /*
        Redirect for a WEIRDLY common error case
        (client history contains non-existent URL)
        */
        path: "/login",
        element: <Desktop />,
        children: desktopChildren,
      },
      {
        path: "/mobile",
        element: <Mobile />,
      },
      {
        path: "/",
        element: <Desktop />,
        children: desktopChildren,
      },
    ],
  },
]

export default routes
